import React from "react";
import "./style.scss";
import { Link } from "gatsby";

const Sidebar = ({ recent_story }) => {
  const recent_articles = [];
  for (let index = 0; index < 7; index++) {
    const element = recent_story[index];
    recent_articles.push(element);
  }

  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  return (
    <aside className="sidebar">
      <div className="list">
        <div className="list-head">
          <span>Recent Featured Articles</span>
        </div>
        {recent_articles.map((element) => {
          return (
            <Link to={`/${element?.slug}`}>
              <div className="list-item">
                <div className="list-item-image">
                  <figure className="image w70h70">{element?.image.includes("idc-ads-media") ? <img src={element?.image} /> : <img src={element?.image} />}</figure>
                </div>

                <div className="list-item-content">
                  <div className="list-item-title">{element?.title}</div>
                  <div className="list-item-description">{element?.categories[0]?.name}</div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </aside>
  );
};
export default Sidebar;
