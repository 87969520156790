import React from "react";
import "./style.scss";
import Hero from "./components/hero/Hero";
import List2 from "./components/list/List2";
import Sidebar from "../main/sidebar/Sidebar";

const Home = ({ data }) => {
  let recent_story = data?.cms?.recent_story;
  let hiro_data = recent_story?.filter(checkFetured);
  function checkFetured(element) {
    return element?.is_feature_post;
  }
  return (
    <div className="home-1">
      <div className="container is-max-widescreen mt-2">
        <Hero data={hiro_data} />
      </div>
      <div className="container is-max-widescreen mt-2">
        <div className="columns m-0">
          <div className="column is-two-thirds">
            <List2 data={recent_story} />
          </div>
          <div className="column">
            <Sidebar recent_story={hiro_data} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
