import React from "react";
import Header from "../components/header/Header";
import Home from "../components/category/Home";
import Footer from "../../../../components/footer/Footer";

import { graphql } from "gatsby";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;
  return (
    <div className="layout">
      <Header
        domain={data?.cms?.domain}
        category={data?.cms?.categories}
        allPosts={allPosts}
        engine={options}
      />
      <Home data={data} />
      <Footer
        pages={data?.cms?.pages}
        domain={data?.cms?.domain}
        email={"editor@thenerdseries.com"}
      />
    </div>
  );
};
export default ArticlePage;

export const query = graphql`
  query category($slug: ID!) {
    cms {
      domain: CMS_Domain(id: "1") {
        id
        logo
        logo_light
        domain_url
        name
        description
        favicon
        header_script
      }
      categories: CMS_CategoryByDomain(domain_id: "1") {
        name
        slug
      }
      recent_story: CMS_DomainWiseStories(domain_id: "1", category_id: $slug) {
        image
        slug
        title
        is_feature_post
        categories {
          name
        }
      }
      recent_listicle: CMS_DomainWiseLists(domain_id: 1, category_id: $slug) {
        title
        image
        slug
        is_feature_post
        categories {
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
