import React, { Component } from "react";
import * as JsSearch from "js-search";
import "./search.scss";
import { Link } from "gatsby";
import ClickEvent from "./clickEvent";

class ClientSearch extends Component {
  state = {
    isLoading: true,
    searchResults: [],
    search: null,
    isError: false,
    indexByTitle: false,
    indexByAuthor: false,
    indexByProduct: false,
    termFrequency: true,
    removeStopWords: false,
    searchQuery: "",
    selectedStrategy: "",
    selectedSanitizer: "",
    isDropdown: false,
  };
  /**
   * React lifecycle method that will inject the data into the state.
   */

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.search === null) {
      const { engine } = nextProps;
      return {
        indexByTitle: engine?.TitleIndex,
        indexByAuthor: engine?.AuthorIndex,
        indexByProduct: engine?.ProductIndex,
        termFrequency: engine?.SearchByTerm,
        selectedSanitizer: engine?.searchSanitizer,
        selectedStrategy: engine?.indexStrategy,
      };
    }
    return null;
  }
  async componentDidMount() {
    this.rebuildIndex();
  }
  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const {
      selectedStrategy,
      selectedSanitizer,
      removeStopWords,
      termFrequency,
      indexByTitle,
      indexByAuthor,
      indexByProduct,
    } = this.state;
    const { allPosts } = this.props;
    const dataToSearch = new JsSearch.Search("isbn");
    if (removeStopWords) {
      dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(dataToSearch.tokenizer);
    }
    /**
     * defines an indexing strategy for the data
     * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    if (selectedStrategy === "All") {
      dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    }
    if (selectedStrategy === "Exact match") {
      dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy();
    }
    if (selectedStrategy === "Prefix match") {
      dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    }
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     */
    selectedSanitizer === "Case Sensitive"
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer());
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("isbn"))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex());
    // sets the index attribute for the data
    if (indexByTitle) {
      dataToSearch.addIndex("title");
    }
    // sets the index attribute for the data
    if (indexByAuthor) {
      dataToSearch.addIndex("content");
    }
    dataToSearch.addDocuments(allPosts); // adds the data to be searched
    this.setState({ search: dataToSearch, isLoading: false });
  };
  /**
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  searchData = (e) => {
    const { search } = this.state;
    const queryResult = search.search(e.target.value);
    this.setState({ searchQuery: e.target.value, searchResults: queryResult });
  };
  handleSubmit = (e) => {
    e.preventDefault();
  };

  activeDropdown = () => {
    this.setState({ isDropdown: true });
  };
  inactiveDropdown = () => {
    this.setState({ isDropdown: false });
  };

  render() {
    const { searchResults, searchQuery, isDropdown } = this.state;
    const { allPosts } = this.props;
    // const queryResults = searchQuery === "" ? allPosts : searchResults;
    const queryResults = allPosts?.filter((e) => e?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase()));
    return (
      <div>
        <ClickEvent
          onClickAway={() => {
            this.inactiveDropdown();
          }}
        >
          <div className="search-element-wrapper" onChange={this.activeDropdown}>
            <form onSubmit={this.handleSubmit}>
              <input
                id="Search"
                name="search"
                value={searchQuery}
                onChange={this.searchData}
                placeholder="Enter Title"
                autocomplete="off"
              />
            </form>
            {isDropdown && queryResults && (
              <div className="search-element">
                <ul>
                  {queryResults?.map((post) => (
                    <li>
                      <Link to={`/${post.slug}`}>{post?.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </ClickEvent>
      </div>
    );
  }
}
export default ClientSearch;
