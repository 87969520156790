import React from "react";
import "./style.scss";
import { Link } from "gatsby";
import logo from "../../images/thenerdseries.png";
import { Helmet } from "react-helmet";
import ClientSearch from "../ClientSearch";
import ReactHtmlParser from "react-html-parser";

const Header = ({ category, domain, allPosts, engine, story }) => {
  return (
    <>
      {/* <pre>{JSON.stringify({ data: withPrefix("js/header.js") }, null, 2)}</pre> */}
      <Helmet>
        <meta charSet="utf-8" />
        {story ? (
          <title>{`${story?.meta_title ? story?.meta_title : story?.title} | ${
            domain?.name
          }`}</title>
        ) : (
          <title>{`${domain?.name}`}</title>
        )}
        <meta name="description" content={story?.meta_description} />
        <meta name="keywords" content={story?.meta_keywords} />
        <meta name="verification" content="8432147577277f08833ae63912630381" />
        <meta
          name="google-site-verification"
          content="HsZU-9FOIln5RxrF10zKqF8tm0BTGPBcvQK5Whb0BPw"
        />
        {ReactHtmlParser(domain?.header_script)}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-6BC3JFH9FM"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-6BC3JFH9FM');`}
        </script>
      </Helmet>
      <header className="header">
        <div className="container is-max-widescreen">
          <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              <Link to={`/`} className="navbar-item">
                <img src={logo} alt="the-nerd-series" />
              </Link>
              <a
                role="button"
                className="navbar-burger"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>

            <div className="navbar-menu">
              <div className="navbar-end">
                <Link to={`/`} className="navbar-item">
                  Home
                </Link>
                {category?.map((categoryData) => {
                  return (
                    <Link to={`/${categoryData.slug}`} className="navbar-item">
                      {categoryData?.name}
                    </Link>
                  );
                })}
                <ClientSearch allPosts={allPosts} engine={engine} />
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
export default Header;
